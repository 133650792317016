import React from "react";
import { useEffect } from "react";
import { useState } from "react";


import CourseService from "../../../services/course.service";
import CoursesStatistic from "../../Admin/CoursesStatistic";

import "../../Admin/admin-dash.css";

import "../../Admin/Responsive.css";
import { Link } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useSelector } from "react-redux";
import CoursesList from "../../../common/CoursesList";
import GraphService from "../../../services/graph.service";
import AllEvents from "../../../common/AllEvents";

const AdminReports = ({ permissions, roleid }) => {
  const [courses, setCourses] = useState([]);
  const [date, setDate] = useState(new Date());
  const userdata = useSelector((state) => state.userDataReducer);
  const themeMode = useSelector((state) => state.themeReducer.themeMode);
  const [currentUser, setCurrentUser] = useState({});
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [currentCourse, setCurrentCourse] = useState(1);
  const [lastSevenDates, setLastSevenDates] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [months, setMonths] = useState([]);
  const [courseEnrolmentValue, setCourseEnrolmentValue] = useState([]);
  const [courseComplitionValue, setCourseComplitionValue] = useState([]);
  const [yAxisData, setYAxisData] = useState([]);
  const [defaultReportFilter, setDefaultReportFilter] = useState("");

  function getWeekNumber(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const days =
      Math.floor((date - firstDayOfYear) / (24 * 60 * 60 * 1000)) + 1;
    const weekNumber = Math.ceil(days / 7);
    return weekNumber;
  }

  const today = new Date();

  useEffect(() => {
    setCurrentUser(userdata);
    getLastSevenDays();
    getMonths();
    getWeeks();

    GraphService.getStudentEnrollmentReportByCourseId(currentCourse)
      .then((resp) => {
        if (resp.data !== false) {
          const arr = resp.data.enrollmentLast15Days.split(",");
          setCourseEnrolmentValue(arr);
          const newArr = resp.data.compsLast15Days.split(",");
          setCourseComplitionValue(newArr);
        }
      })
      .catch((e) => console.log(e));

    CourseService.getCourse(currentUser.user)
      .then((response) => {
        setCourses(response.data);
      })
      .catch((e) => console.log(e));
  }, []);

  const getLastSevenDays = () => {
    const lastFifteenDates = [];
    const today = new Date();
    const currentDate = new Date(today);

    for (let i = 0; i < 15; i++) {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() - i);
      lastFifteenDates.push(date);
    }

    const sortedDatesFifteen = lastFifteenDates.slice().sort((a, b) => a - b);

    const formattedDates = sortedDatesFifteen.map((date) => {
      const day = date.getDate().toString().padStart(2, "0");
      const month = monthNames[date.getMonth()];
      return `${month} ${day}`;
    });

    setLastSevenDates(formattedDates);
    setYAxisData(formattedDates);
  };

  const getMonths = () => {
    const lastSevenMonths = [];

    for (let i = 0; i < 12; i++) {
      const monthStartDate = new Date(today);
      monthStartDate.setMonth(monthStartDate.getMonth() - i);
      lastSevenMonths.push(monthStartDate);
    }

    const sortedMonths = lastSevenMonths.slice().sort((a, b) => {
      const monthA = a.getMonth();
      const monthB = b.getMonth();
      const yearA = a.getFullYear();
      const yearB = b.getFullYear();

      if (yearA === yearB) {
        return monthA - monthB;
      } else {
        return yearA - yearB;
      }
    });

    const formattedDates = sortedMonths.map((date) => {
      const month = monthNames[date.getMonth()];
      const year = date.getFullYear();
      return `${month} ${year}`;
    });

    setMonths(formattedDates);
  };

  const getWeeks = () => {
    const lastSevenWeeks = [];

    for (let i = 0; i < 10; i++) {
      const weekStartDate = new Date(today);
      weekStartDate.setDate(today.getDate() - i * 7);
      const weekNumber = getWeekNumber(weekStartDate);
      const formattedWeek = `Week ${weekNumber}`;

      lastSevenWeeks.push({ formattedWeek, startDate: weekStartDate });
    }

    lastSevenWeeks.sort((a, b) => {
      return a.startDate - b.startDate;
    });

    const sortedWeeks = lastSevenWeeks.map((week) => week.formattedWeek);

    setWeeks(sortedWeeks);
  };

  const courseFilter = (id) => {
    setCurrentCourse(id);
    if (defaultReportFilter == 0) {
      setYAxisData(lastSevenDates);
      GraphService.getStudentEnrollmentReportByCourseId(id)
        .then((resp) => {
          const arr = resp.data.enrollmentLast15Days.split(",");
          setCourseEnrolmentValue(arr);
          const newArr = resp.data.compsLast15Days.split(",");
          setCourseComplitionValue(newArr);
        })
        .catch((e) => console.log(e));
    } else if (defaultReportFilter == 1) {
      setYAxisData(weeks);
      GraphService.getStudentEnrollmentReportByCourseId(id)
        .then((resp) => {
          const arr = resp.data.enrollmentLastWeek.split(",");
          setCourseEnrolmentValue(arr);
          const newArr = resp.data.compsLastWeek.split(",");
          setCourseComplitionValue(newArr);
        })
        .catch((e) => console.log(e));
    } else if (defaultReportFilter == 2) {
      setYAxisData(months);
      GraphService.getStudentEnrollmentReportByCourseId(id)
        .then((resp) => {
          const arr = resp.data.enrollmentLastMonth.split(",");
          setCourseEnrolmentValue(arr);
          const newArr = resp.data.compsLastMonth.split(",");
          setCourseComplitionValue(newArr);
        })
        .catch((e) => console.log(e));
    }
  };

  const filterReport = (e, cid) => {
    setDefaultReportFilter(e.target.value);
    if (e.target.value == 0) {
      setYAxisData(lastSevenDates);
      GraphService.getStudentEnrollmentReportByCourseId(cid)
        .then((resp) => {
          const arr = resp.data.enrollmentLast15Days.split(",");
          setCourseEnrolmentValue(arr);
          const newArr = resp.data.compsLast15Days.split(",");
          setCourseComplitionValue(newArr);
        })
        .catch((e) => console.log(e));
    } else if (e.target.value == 1) {
      setYAxisData(weeks);
      GraphService.getStudentEnrollmentReportByCourseId(cid)
        .then((resp) => {
          const arr = resp.data.enrollmentLastWeek.split(",");
          setCourseEnrolmentValue(arr);
          const newArr = resp.data.compsLastWeek.split(",");
          setCourseComplitionValue(newArr);
        })
        .catch((e) => console.log(e));
    } else if (e.target.value == 2) {
      setYAxisData(months);
      GraphService.getStudentEnrollmentReportByCourseId(cid)
        .then((resp) => {
          const arr = resp.data.enrollmentLastMonth.split(",");
          setCourseEnrolmentValue(arr);
          const newArr = resp.data.compsLastMonth.split(",");
          setCourseComplitionValue(newArr);
        })
        .catch((e) => console.log(e));
    }
  };

  return (
    <>
      <div className="container-fluid admin-dash mt-3">
        <div className="row">
          <div className="col-xl-8 col-lg-8 mb-3">
            <div className="outer-box">
              <div className="cflex">
                <div className="heading">
                  <h1>Filter by Courses</h1>
                </div>
                <div className="heading">
                  <h1>Courses Statistic</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-3">
                  <div className="course-list">
                    {Array.isArray(courses)
                      ? courses.map((course) => {
                          return (
                            <div
                              key={course.id}
                              className={
                                currentCourse == course.id
                                  ? "course-item active"
                                  : "course-item"
                              }
                            >
                              <button
                                onClick={(e) => courseFilter(course.id)}
                                title={course.name}
                              >
                                <span>{course.shortname}</span>
                              </button>
                            </div>
                          );
                        })
                      : null}

                    <div className="course-item">
                      <Link to="/ManageCourses">
                        <button>
                          <span>View All</span>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-9">
                  <div className="inner-box">
                    {Array.isArray(courses)
                      ? courses.map((coursenew) => {
                          return (
                            <div key={coursenew.id}>
                              {currentCourse == coursenew.id ? (
                                <>
                                  <div className="cflex">
                                    <div className="heading">
                                      <h1>{coursenew.name}</h1>
                                    </div>
                                    <div className="error-req flex-end">
                                      <div className="">
                                        <div className="select">
                                          <select
                                            name=""
                                            id=""
                                            value={defaultReportFilter}
                                            onChange={(e) =>
                                              filterReport(e, coursenew.id)
                                            }
                                          >
                                            <option value="0">Day</option>
                                            <option value="1">Week</option>
                                            <option value="2">Month</option>
                                          </select>
                                          <div className="icon">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16.16"
                                              height="17.486"
                                              viewBox="0 0 16.16 17.486"
                                            >
                                              <g
                                                id="sort"
                                                transform="translate(0 0)"
                                              >
                                                <path
                                                  id="Path_11988"
                                                  data-name="Path 11988"
                                                  d="M53.177-479.89a1.386,1.386,0,0,0-.429.355c-.16.172-1.023,1.058-1.913,1.964C49.183-475.884,49-475.666,49-475.4a.8.8,0,0,0,.269.613.757.757,0,0,0,.921.164c.066-.031.648-.59,1.3-1.234l1.179-1.179v4.447c0,3.4.012,4.482.047,4.607a.879.879,0,0,0,.187.3.847.847,0,0,0,1.4-.383c.031-.148.043-1.5.043-4.665v-4.458l1.3,1.3a12.954,12.954,0,0,0,1.413,1.327.847.847,0,0,0,1.089-.808.963.963,0,0,0-.066-.336c-.035-.066-.91-.953-1.948-1.971s-1.936-1.909-2-1.983A.906.906,0,0,0,53.177-479.89Z"
                                                  transform="translate(-49 479.938)"
                                                />
                                                <path
                                                  id="Path_11989"
                                                  data-name="Path 11989"
                                                  d="M232.573-351.854a.88.88,0,0,0-.445.39,35.329,35.329,0,0,0-.078,4.661l-.008,4.548-1.261-1.253c-1.206-1.2-1.265-1.253-1.456-1.3a.834.834,0,0,0-.933,1.167c.031.059.867.9,1.862,1.878s1.839,1.827,1.874,1.893a.892.892,0,0,0,1.2.336c.265-.144,3.877-3.822,4.037-4.115a.724.724,0,0,0-.164-.933.744.744,0,0,0-.941-.148c-.074.035-.672.605-1.327,1.261l-1.191,1.195-.02-4.536-.02-4.54-.109-.187A.848.848,0,0,0,232.573-351.854Z"
                                                  transform="translate(-221.306 356.907)"
                                                />
                                              </g>
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <CoursesStatistic
                                    yAxisData={yAxisData}
                                    corsid={coursenew.id}
                                    courseEnrolmentValue={courseEnrolmentValue}
                                    courseComplitionValue={
                                      courseComplitionValue
                                    }
                                    themeMode={themeMode}
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 mb-3">
            <div className="outer-box">
              <div className="cflex">
                <div className="heading">
                  <h1>Schedule and Submissions</h1>
                </div>
                <button>
                  <Link to={"/Calendar"}>Create New Event</Link>
                </button>
              </div>
              <div className="flex-cal">
                <Calendar onChange={setDate} value={date} />
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-lg-8 mb-3">
            <div className="outer-box">
              <div className="inner-box">
                <div className="cflex">
                  <div className="heading">
                    <h1>Manage Courses</h1>
                  </div>
                </div>
                <CoursesList permissions={permissions} roleid={roleid} />
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 mb-3">
            <div className="outer-box">
              <div className="flex-cal">
                <AllEvents />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminReports;

import React, { useState, useEffect } from "react";
import CourseEnrolment from "./Charts/CourseEnrolment";
import StudentEngagement from "./Charts/StudentEngagement";
import ErrorReports from "./Charts/ErrorReports";
import CourseOverview from "./Charts/CourseOverview";
import EstorePerformance from "./Charts/EstorePerformance";
import CourseTimeEngagement from "./Charts/CourseTimeEngagement";
import CourseGradeEngagement from "./Charts/CourseGradeEngagement";
import GaugeChart from "react-gauge-chart";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { Form, FloatingLabel } from "react-bootstrap";

import UserService from "../../../services/user.service";
import GraphService from "../../../services/graph.service";
import AuthService from "../../../services/auth.service";
import CourseService from "../../../services/course.service";

import AccountDashboard from "../../Account/AccountDashboard";

import { FaUserCircle } from "react-icons/fa";
import { FaChalkboardTeacher } from "react-icons/fa";
import { PiCertificateFill } from "react-icons/pi";
import { MdFeedback } from "react-icons/md";
import { FaRegIdCard } from "react-icons/fa6";
import { BiSolidPurchaseTag } from "react-icons/bi";
import ReportService from "../../../services/report.service";
import CoursePerformance from "./Charts/CoursePerformance";
import AdminReports from "./AdminReports";

const SADashboard = () => {
  const [lastSevenDates, setLastSevenDates] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [months, setMonths] = useState([]);

  const [stats, setStats] = useState({
    Certificate_Issued_count: "0",
    Online_registered_std_count: "0",
    offline_registered_std_count: "0",
    onlinecouseperchasecount: "0",
    student_Feedback_count: "0",
    studentcsenrollcount: "0",
  });

  const [estoreCourses, setEstoreCourses] = useState([]);

  const [inactiveAdmins, setInactiveAdmins] = useState(0);
  const [activeAdmins, setActiveAdmins] = useState(0);

  const [yAxisData, setYAxisData] = useState([]);

  const themeMode = useSelector((state) => state.themeReducer.themeMode);

  const [filterDate, setFilterDate] = useState("");

  const [courseEnrolmentData, setCourseEnrolmentData] = useState([]);
  const [courseEnrolmentValue, setCourseEnrolmentValue] = useState([]);
  const [courseCompletionValue, setCourseCompletionValue] = useState([]);

  const [studentEngagementData, setStudentEngagementData] = useState([]);
  const [estoreCourseData, setEstoreCourseData] = useState([]);
  const [estoreCourseValue, setEstoreCourseValue] = useState([]);
  const [learnerTimeData, setLearnerTimeData] = useState([]);
  const [learnerGradesData, setLearnerGradesData] = useState([]);

  const [defaultReportFilter, setDefaultReportFilter] = useState("");

  const [courseEnrollmentFilter, setCourseEnrollmentFilter] = useState("");
  const [studentEngagementFilter, setStudentEngagementFilter] = useState("");
  const [estoreCourseFilter, setEstoreCourseFilter] = useState("");
  const [learnerTimeFilter, setLearnerTimeFilter] = useState("");
  const [learnerGradesFilter, setLearnerGradesFilter] = useState("");

  const [activeLearnerCount, setActiveLearnersCount] = useState([]);

  const [years, setYears] = useState([]);

  const [selectedPeriod, setSelectedPeriod] = useState({
    year: "",
    month: "",
  });

  const [courseDetails, setCourseDetails] = useState({
    courseNames: "",
    courseCounts: "",
  });

  const formatDateString = (date) => {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${day}-${month}`;
  };

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  function getWeekNumber(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const days =
      Math.floor((date - firstDayOfYear) / (24 * 60 * 60 * 1000)) + 1;
    const weekNumber = Math.ceil(days / 7);
    return weekNumber;
  }

  const today = new Date();

  const getCurrentDate = () => {
    const date = new Date();
    
    date.setDate(date.getDate() - 1);

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const currentDate = `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;

    const prevYear = date.getFullYear() - 1;

    const years = [year, prevYear];

    setSelectedPeriod({
      year: year,
      month: month
    })

    setYears(years);

    fetchCourseEnrollments(year, month);


    const payload = {
      sdate: currentDate,
    };
    ReportService.getDashboardData(payload)
      .then((resp) => {
        console.log(resp.data);
        setStats(resp.data);
      })
      .catch((e) => console.log(e));
    setFilterDate(currentDate);
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    getStaffsData(user.user);
  }, [activeAdmins, inactiveAdmins]);

  useEffect(() => {
    getLastSevenDays();
    getMonths();
    getWeeks();
    getAllEstoreCourses();
    getCurrentDate();

    const user = AuthService.getCurrentUser();

    const data = {
      cid: estoreCourseFilter,
    };

    GraphService.getEstoreCourseGraph(data)
      .then((resp) => {
        setEstoreCourseValue(resp.data.last15Days.counts);
      })
      .catch((e) => console.log(e));

    GraphService.getCourseEnrollmentReport(user.user)
      .then((resp) => {
        const arr = resp.data.enrollmentLast15Days.split(",");
        const newArr = resp.data.compLast15Days.split(",");
        setCourseEnrolmentValue(arr);
        setCourseCompletionValue(newArr);
      })
      .catch((e) => console.log(e));

    GraphService.getActiveLearners()
      .then((resp) => {
        setActiveLearnersCount(resp.data.learningview15Days);
      })
      .catch((e) => console.log(e));
  }, []);

  const getAllEstoreCourses = () => {
    const data = {
      usp: "",
      rid: "",
    };
    CourseService.getEstoreCourses(data)
      .then((resp) => {
        setEstoreCourses(resp.data);
        setEstoreCourseFilter(resp.data[0].course_id);
      })
      .catch((e) => console.log(e));
  };

  const getStaffsData = (uid) => {
    UserService.getSystemManagers(uid)
      .then((response) => {
        const staffsData = response.data;
        const inactiveCount = Array.isArray(staffsData)
          ? staffsData.filter((admin) => admin.status === "0").length
          : null;
        const activeCount = Array.isArray(staffsData)
          ? staffsData.filter((admin) => admin.status === "1").length
          : null;
        setInactiveAdmins(inactiveCount / response.data.length);
        setActiveAdmins(activeCount / response.data.length);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getLastSevenDays = () => {
    const lastFifteenDates = [];
    const today = new Date();
    const currentDate = new Date(today);

    for (let i = 0; i < 15; i++) {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() - i);
      lastFifteenDates.push(date);
    }

    const sortedDatesFifteen = lastFifteenDates.slice().sort((a, b) => a - b);

    const formattedDates = sortedDatesFifteen.map((date) => {
      const day = date.getDate().toString().padStart(2, "0");
      // const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const month = monthNames[date.getMonth()];
      return `${month} ${day}`;
    });

    // Assuming these functions are available and you are handling state updates correctly
    setLastSevenDates(formattedDates);
    setCourseEnrolmentData(formattedDates);
    setStudentEngagementData(formattedDates);
    setEstoreCourseData(formattedDates);
    setLearnerTimeData(formattedDates);
    setLearnerGradesData(formattedDates);
  };

  const getMonths = () => {
    const lastSevenMonths = [];

    for (let i = 0; i < 12; i++) {
      const monthStartDate = new Date(today);
      monthStartDate.setMonth(monthStartDate.getMonth() - i);

      // const month = (monthStartDate.getMonth() + 1).toString().padStart(2, '0'); // Get month as two-digit string
      // const year = monthStartDate.getFullYear();
      // const formattedDate = `${month}-${year}`;

      lastSevenMonths.push(monthStartDate);
    }

    const sortedMonths = lastSevenMonths.slice().sort((a, b) => {
      const monthA = a.getMonth();
      const monthB = b.getMonth();
      const yearA = a.getFullYear();
      const yearB = b.getFullYear();

      if (yearA === yearB) {
        return monthA - monthB;
      } else {
        return yearA - yearB;
      }
    });

    const formattedDates = sortedMonths.map((date) => {
      // const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const month = monthNames[date.getMonth()];
      const year = date.getFullYear();
      return `${month} ${year}`;
    });

    setMonths(formattedDates);
  };

  const getWeeks = () => {
    const lastSevenWeeks = [];

    for (let i = 0; i < 10; i++) {
      const weekStartDate = new Date(today);
      weekStartDate.setDate(today.getDate() - i * 7);
      const weekNumber = getWeekNumber(weekStartDate);
      const formattedWeek = `Week ${weekNumber}`;

      lastSevenWeeks.push({ formattedWeek, startDate: weekStartDate });
    }

    lastSevenWeeks.sort((a, b) => {
      return a.startDate - b.startDate;
    });

    const sortedWeeks = lastSevenWeeks.map((week) => week.formattedWeek);

    setWeeks(sortedWeeks);
  };

  const filterCourseEnrollment = (e) => {
    setCourseEnrollmentFilter(e.target.value);
    if (e.target.value == 0) {
      setCourseEnrolmentData(lastSevenDates);
      const user = AuthService.getCurrentUser();
      GraphService.getCourseEnrollmentReport(user.user)
        .then((resp) => {
          const arr = resp.data.enrollmentLast15Days.split(",");
          const newArr = resp.data.compLast15Days.split(",");
          setCourseEnrolmentValue(arr);
          setCourseCompletionValue(newArr);
        })
        .catch((e) => console.log(e));
    } else if (e.target.value == 1) {
      setCourseEnrolmentData(weeks);
      const user = AuthService.getCurrentUser();
      GraphService.getCourseEnrollmentReport(user.user)
        .then((resp) => {
          const arr = resp.data.enrollmentLastWeek.split(",");
          const newArr = resp.data.compLastWeek.split(",");
          setCourseEnrolmentValue(arr);
          setCourseCompletionValue(newArr);
        })
        .catch((e) => console.log(e));
    } else if (e.target.value == 2) {
      setCourseEnrolmentData(months);
      const user = AuthService.getCurrentUser();
      GraphService.getCourseEnrollmentReport(user.user)
        .then((resp) => {
          const arr = resp.data.enrollmentLastMonth.split(",");
          const newArr = resp.data.compLastMonth.split(",");
          setCourseEnrolmentValue(arr);
          setCourseCompletionValue(newArr);
        })
        .catch((e) => console.log(e));
    }
  };

  const filterStudentEngagement = (e) => {
    setStudentEngagementFilter(e.target.value);
    if (e.target.value == 0) {
      setStudentEngagementData(lastSevenDates);
      GraphService.getActiveLearners()
        .then((resp) => {
          setActiveLearnersCount(resp.data.learningview15Days);
        })
        .catch((e) => console.log(e));
    } else if (e.target.value == 1) {
      setStudentEngagementData(weeks);
      GraphService.getActiveLearners()
        .then((resp) => {
          setActiveLearnersCount(resp.data.learningviewWeek);
        })
        .catch((e) => console.log(e));
    } else if (e.target.value == 2) {
      setStudentEngagementData(months);
      GraphService.getActiveLearners()
        .then((resp) => {
          setActiveLearnersCount(resp.data.learningviewMonth);
        })
        .catch((e) => console.log(e));
    }
  };

  const filterEstorePerformance = (e) => {
    setEstoreCourseFilter(e.target.value);

    const data = {
      cid: e.target.value,
    };

    GraphService.getEstoreCourseGraph(data)
      .then((resp) => {
        setEstoreCourseValue(resp.data.last15Days.counts);
      })
      .catch((e) => console.log(e));
  };

  const filterLearnerTime = (e) => {
    setLearnerTimeFilter(e.target.value);
    if (e.target.value == 0) {
      setLearnerTimeData(lastSevenDates);
    } else if (e.target.value == 1) {
      setLearnerTimeData(weeks);
    } else if (e.target.value == 2) {
      setLearnerTimeData(months);
    }
  };

  const filterLearnerGrades = (e) => {
    setLearnerGradesFilter(e.target.value);
    if (e.target.value == 0) {
      setLearnerGradesData(lastSevenDates);
    } else if (e.target.value == 1) {
      setLearnerGradesData(weeks);
    } else if (e.target.value == 2) {
      setLearnerGradesData(months);
    }
  };

  const handleFilterDate = (e) => {
    setFilterDate(e.target.value);
    const payload = {
      sdate: e.target.value,
    };
    ReportService.getDashboardData(payload)
      .then((resp) => {
        console.log(resp.data);
        setStats(resp.data);
      })
      .catch((e) => console.log(e));
  };

  const fetchCourseEnrollments = (year, month) => {
    const paylaod = {
      csyear: year,
      csmonth: month,
    };
    ReportService.getCourseEnrollmentsCount(paylaod)
      .then((resp) => {
        console.log(resp.data);
        const data = resp.data.TpoCourse_enrollment;
        // const course_names = [...new Set(data.map((item) => item.course_name))];
        const course_names = data.map(value => Object.values(value)[1]);
        const course_counts = data.map(value => Object.values(value)[2]);

        setCourseDetails({
          courseNames: course_names,
          courseCounts: course_counts
        })

        console.log("courses", course_names, course_counts)

        // const uniqueDates = [...new Set(data.map((item) => item.createdOn))];
      })
      .catch((e) => console.log(e));
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setSelectedPeriod({ ...selectedPeriod, [name]: value });

    if (name === "year") {
      fetchCourseEnrollments(value, selectedPeriod.month);
    } else if (name === "month") {
      fetchCourseEnrollments(selectedPeriod.year, value);
    }
  };

  return (
    <>
      <div className="container-fluid mb-3 superadmin">
        <div className="row">
          <div className="col-lg-12 mb-3">
            <div className="outer-box">
              <div className="cflex mb-2">
                <div className="heading">
                  <h1>Stats</h1>
                </div>
                <div className="search-bar form-flex"></div>
                <Form.Group controlId="formBasicOrgName" className="date-input">
                  <div className="input-box">
                    <Form.Control
                      type="date"
                      name="filterDate"
                      value={filterDate}
                      onChange={handleFilterDate}
                    />
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="14.02"
                        viewBox="0 0 81.923 76.58"
                      >
                        <g id="calendar" transform="translate(-15 482)">
                          <path
                            id="Path_13206"
                            data-name="Path 13206"
                            d="M20.071-481.763a6.872,6.872,0,0,0-4.139,3.494c-.8,1.577-.933,2.68-.933,8.175v5.054H96.77l-.051-5.394-.051-5.377-.543-1.153a9.468,9.468,0,0,0-6.31-4.8A33.656,33.656,0,0,0,84.592-482H80.47v3.85a39.256,39.256,0,0,1-.17,4.461,3,3,0,0,1-.763,1.187,1.965,1.965,0,0,1-1.476.645,1.8,1.8,0,0,1-1.476-.339c-1.289-.882-1.289-.865-1.34-5.58L75.179-482H34.505v3.681c-.017,4.427-.1,4.987-.9,5.767a2.54,2.54,0,0,1-3.9-.441c-.356-.577-.373-.814-.424-4.817L29.23-482l-4.189.017A31.215,31.215,0,0,0,20.071-481.763Z"
                          />
                          <path
                            id="Path_13207"
                            data-name="Path 13207"
                            d="M15.034-326.627l.051,24.39.441,1.119a6.553,6.553,0,0,0,4.122,4.172c.814.288,2.612.305,35.551.305H89.884l1.136-.39a9.341,9.341,0,0,0,5.275-4.444l.543-1.1.051-24.2L96.923-351H15ZM38.9-341.281a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.935.051,3.155.034,3.528-.271,4.223-.594,1.34-.746,1.374-5.054,1.374-5.343,0-5.156.2-5.156-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,38.9-341.281Zm20.184,0a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.986.068,3.9-.051,4.58-.865,5.19-.458.339-.7.356-4.359.356-5.462,0-5.258.22-5.258-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,59.082-341.281Zm20.54.034c.933.492,1.018.882,1.018,5,0,5.445.271,5.173-5.241,5.173-3.731,0-3.85-.017-4.376-.39-.78-.56-.9-1.187-.9-4.919,0-3.562.1-4.1.933-4.749.458-.356.628-.373,4.274-.373C78.4-341.5,79.232-341.451,79.622-341.247ZM39.2-318.129c.78.56.9,1.187.9,4.834,0,3.46-.1,3.935-.916,4.7-.458.424-.475.424-4.274.475-3.613.051-3.85.034-4.376-.305-.916-.56-.967-.882-.916-5.139.051-3.782.051-3.833.458-4.257a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C38.508-318.519,38.678-318.5,39.2-318.129Zm20.082-.085c.848.577.916.9.916,4.851a36.048,36.048,0,0,1-.136,4.139,1.659,1.659,0,0,1-.678.8c-.492.305-.865.339-4.325.339-5.563,0-5.326.237-5.258-5.428.051-3.85.051-3.884.458-4.308a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C58.37-318.519,58.9-318.485,59.286-318.214Zm19.845-.221a2.965,2.965,0,0,1,1.018.611l.407.441v8.277l-.475.458-.458.475H71.345l-.441-.407c-.712-.678-.814-1.34-.746-5.139.068-4.953-.017-4.868,5.139-4.868A28.455,28.455,0,0,1,79.131-318.434Z"
                            transform="translate(0 -108.781)"
                          />
                        </g>
                      </svg>
                    </span>
                  </div>
                </Form.Group>
              </div>
              <div className="row">
                <div className="col-lg-2">
                  <div className="inner-box">
                    <Link to={"/UserEnrollment"}>
                      <div className="d-flex flex-column align-items-center stats">
                        <span className="stats-icon">
                          <FaUserCircle />
                        </span>
                        <span className="count">
                          {stats.offline_registered_std_count}
                        </span>
                        <span>Registered Learners</span>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="inner-box">
                    <Link to={"/CourseEnrollment"}>
                      <div className="d-flex flex-column align-items-center stats">
                        <span className="stats-icon">
                          <FaChalkboardTeacher />
                        </span>
                        <span className="count">
                          {stats.studentcsenrollcount}
                        </span>
                        <span>Course Enrolled</span>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="inner-box">
                    <Link to={"/CertificateReports"}>
                      <div className="d-flex flex-column align-items-center stats">
                        <span className="stats-icon">
                          <PiCertificateFill />
                        </span>
                        <span className="count">
                          {stats.Certificate_Issued_count}
                        </span>
                        <span>Certificate Issued</span>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="inner-box">
                    <Link to={"/FeedbackReport"}>
                      <div className="d-flex flex-column align-items-center stats">
                        <span className="stats-icon">
                          <MdFeedback />
                        </span>
                        <span className="count">
                          {stats.student_Feedback_count}
                        </span>
                        <span>Feedback Recieved</span>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="inner-box">
                    <Link to={"/UserEnrollment"}>
                      <div className="d-flex flex-column align-items-center stats">
                        <span className="stats-icon">
                          <FaRegIdCard />
                        </span>
                        <span className="count">
                          {stats.Online_registered_std_count}
                        </span>
                        <span>Online Registered</span>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="inner-box">
                    <Link to={"/PaymentHistory"}>
                      <div className="d-flex flex-column align-items-center stats">
                        <span className="stats-icon">
                          <BiSolidPurchaseTag />
                        </span>
                        <span className="count">
                          {stats.onlinecouseperchasecount}
                        </span>
                        <span>Purchased Courses</span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 mb-3">
            <div className="outer-box three">
              <div className="heading">
                <h1>Reports</h1>
              </div>
              <div className="row">
                <div className="col-xl-6 col-lg-6">
                  <div className="inner-box">
                    <div className="cflex">
                      <div className="heading">
                        <h1>Student Course Enrollment</h1>
                      </div>
                      <div className="error-req flex-end">
                        <div className="">
                          <div className="select">
                            <select
                              name=""
                              id=""
                              value={courseEnrollmentFilter}
                              onChange={filterCourseEnrollment}
                            >
                              <option value="0">Day</option>
                              <option value="1">Week</option>
                              <option value="2">Month</option>
                            </select>
                            <div className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16.16"
                                height="17.486"
                                viewBox="0 0 16.16 17.486"
                              >
                                <g id="sort" transform="translate(0 0)">
                                  <path
                                    id="Path_11988"
                                    data-name="Path 11988"
                                    d="M53.177-479.89a1.386,1.386,0,0,0-.429.355c-.16.172-1.023,1.058-1.913,1.964C49.183-475.884,49-475.666,49-475.4a.8.8,0,0,0,.269.613.757.757,0,0,0,.921.164c.066-.031.648-.59,1.3-1.234l1.179-1.179v4.447c0,3.4.012,4.482.047,4.607a.879.879,0,0,0,.187.3.847.847,0,0,0,1.4-.383c.031-.148.043-1.5.043-4.665v-4.458l1.3,1.3a12.954,12.954,0,0,0,1.413,1.327.847.847,0,0,0,1.089-.808.963.963,0,0,0-.066-.336c-.035-.066-.91-.953-1.948-1.971s-1.936-1.909-2-1.983A.906.906,0,0,0,53.177-479.89Z"
                                    transform="translate(-49 479.938)"
                                  />
                                  <path
                                    id="Path_11989"
                                    data-name="Path 11989"
                                    d="M232.573-351.854a.88.88,0,0,0-.445.39,35.329,35.329,0,0,0-.078,4.661l-.008,4.548-1.261-1.253c-1.206-1.2-1.265-1.253-1.456-1.3a.834.834,0,0,0-.933,1.167c.031.059.867.9,1.862,1.878s1.839,1.827,1.874,1.893a.892.892,0,0,0,1.2.336c.265-.144,3.877-3.822,4.037-4.115a.724.724,0,0,0-.164-.933.744.744,0,0,0-.941-.148c-.074.035-.672.605-1.327,1.261l-1.191,1.195-.02-4.536-.02-4.54-.109-.187A.848.848,0,0,0,232.573-351.854Z"
                                    transform="translate(-221.306 356.907)"
                                  />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <CourseEnrolment
                      courseEnrolmentData={courseEnrolmentData}
                      courseEnrolmentValue={courseEnrolmentValue}
                      courseCompletionValue={courseCompletionValue}
                      themeMode={themeMode}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                  <div className="inner-box">
                    <div className="cflex">
                      <div className="heading">
                        <h1>Student Engagement</h1>
                      </div>
                      <div className="error-req flex-end">
                        <div className="">
                          <div className="select">
                            <select
                              name=""
                              id=""
                              value={studentEngagementFilter}
                              onChange={filterStudentEngagement}
                            >
                              <option value="0">Day</option>
                              <option value="1">Week</option>
                              <option value="2">Month</option>
                            </select>
                            <div className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16.16"
                                height="17.486"
                                viewBox="0 0 16.16 17.486"
                              >
                                <g id="sort" transform="translate(0 0)">
                                  <path
                                    id="Path_11988"
                                    data-name="Path 11988"
                                    d="M53.177-479.89a1.386,1.386,0,0,0-.429.355c-.16.172-1.023,1.058-1.913,1.964C49.183-475.884,49-475.666,49-475.4a.8.8,0,0,0,.269.613.757.757,0,0,0,.921.164c.066-.031.648-.59,1.3-1.234l1.179-1.179v4.447c0,3.4.012,4.482.047,4.607a.879.879,0,0,0,.187.3.847.847,0,0,0,1.4-.383c.031-.148.043-1.5.043-4.665v-4.458l1.3,1.3a12.954,12.954,0,0,0,1.413,1.327.847.847,0,0,0,1.089-.808.963.963,0,0,0-.066-.336c-.035-.066-.91-.953-1.948-1.971s-1.936-1.909-2-1.983A.906.906,0,0,0,53.177-479.89Z"
                                    transform="translate(-49 479.938)"
                                  />
                                  <path
                                    id="Path_11989"
                                    data-name="Path 11989"
                                    d="M232.573-351.854a.88.88,0,0,0-.445.39,35.329,35.329,0,0,0-.078,4.661l-.008,4.548-1.261-1.253c-1.206-1.2-1.265-1.253-1.456-1.3a.834.834,0,0,0-.933,1.167c.031.059.867.9,1.862,1.878s1.839,1.827,1.874,1.893a.892.892,0,0,0,1.2.336c.265-.144,3.877-3.822,4.037-4.115a.724.724,0,0,0-.164-.933.744.744,0,0,0-.941-.148c-.074.035-.672.605-1.327,1.261l-1.191,1.195-.02-4.536-.02-4.54-.109-.187A.848.848,0,0,0,232.573-351.854Z"
                                    transform="translate(-221.306 356.907)"
                                  />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <StudentEngagement
                      studentEngagementData={studentEngagementData}
                      activeLearnerCount={activeLearnerCount}
                      themeMode={themeMode}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 mb-3">
            <div className="outer-box two">
              <div className="row">
                <div className="col-xl-6 col-lg-6">
                  <div className="inner-box">
                    <div className="heading">
                      <h1>Error reports and Service Requrests</h1>
                    </div>
                    <ErrorReports />
                  </div>
                </div>
                {/* <div className="col-xl-4 col-lg-4">
                  <div className="inner-box">
                    <div className="heading">
                      <h1>Manage People</h1>
                    </div>
                    <div className="mng-ppl-chart">
                      <GaugeChart
                        id="gauge-chart1"
                        nrOfLevels={2}
                        colors={["#388BFD", "#E8E8E8"]}
                        percent={activeAdmins}
                        needleBaseColor="#3F3E40"
                        needleColor="#3F3E40"
                        arcsLength={[activeAdmins, inactiveAdmins]}
                        // arcsLength={[0.6, 0.4]}
                        arcPadding={0}
                        cornerRadius={0}
                        arcWidth={0.3}
                        // style={{'background': 'transparent linear-gradient(90deg, #CAE8FF 0%, #388BFD 100%) 0% 0% no-repeat padding-box;'}}
                      />
                      <div className="inactiveAdmin">
                        <p>Active Admin</p>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-xl-6 col-lg-6">
                  <div className="inner-box">
                    <div className="heading">
                      <h1>Manage Courses</h1>
                    </div>
                    <CourseOverview />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-lg-12">
            <div className="outer-box three">
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <div className="inner-box estore">
                    <div className="cflex">
                      <div className="heading">
                        <h1>Courses Performance</h1>
                      </div>
                      <div className="error-req course">
                        <div className="d-flex gap-2">
                          <div className="select">
                            <select
                              name="year"
                              value={selectedPeriod.year}
                              onChange={handleSelectChange}
                            >
                              <option value="0">Select Year</option>
                              {Array.isArray(years) ? years.map((item) => {
                                return (
                                  <>
                                   <option value={item}>{item}</option>
                                   </>
                                )
                              }) : null}
                             
                            </select>
                            <div className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16.16"
                                height="17.486"
                                viewBox="0 0 16.16 17.486"
                              >
                                <g id="sort" transform="translate(0 0)">
                                  <path
                                    id="Path_11988"
                                    data-name="Path 11988"
                                    d="M53.177-479.89a1.386,1.386,0,0,0-.429.355c-.16.172-1.023,1.058-1.913,1.964C49.183-475.884,49-475.666,49-475.4a.8.8,0,0,0,.269.613.757.757,0,0,0,.921.164c.066-.031.648-.59,1.3-1.234l1.179-1.179v4.447c0,3.4.012,4.482.047,4.607a.879.879,0,0,0,.187.3.847.847,0,0,0,1.4-.383c.031-.148.043-1.5.043-4.665v-4.458l1.3,1.3a12.954,12.954,0,0,0,1.413,1.327.847.847,0,0,0,1.089-.808.963.963,0,0,0-.066-.336c-.035-.066-.91-.953-1.948-1.971s-1.936-1.909-2-1.983A.906.906,0,0,0,53.177-479.89Z"
                                    transform="translate(-49 479.938)"
                                  />
                                  <path
                                    id="Path_11989"
                                    data-name="Path 11989"
                                    d="M232.573-351.854a.88.88,0,0,0-.445.39,35.329,35.329,0,0,0-.078,4.661l-.008,4.548-1.261-1.253c-1.206-1.2-1.265-1.253-1.456-1.3a.834.834,0,0,0-.933,1.167c.031.059.867.9,1.862,1.878s1.839,1.827,1.874,1.893a.892.892,0,0,0,1.2.336c.265-.144,3.877-3.822,4.037-4.115a.724.724,0,0,0-.164-.933.744.744,0,0,0-.941-.148c-.074.035-.672.605-1.327,1.261l-1.191,1.195-.02-4.536-.02-4.54-.109-.187A.848.848,0,0,0,232.573-351.854Z"
                                    transform="translate(-221.306 356.907)"
                                  />
                                </g>
                              </svg>
                            </div>
                          </div>
                          <div className="select">
                            <select
                              name="month"
                              value={selectedPeriod.month}
                              onChange={handleSelectChange}
                            >
                              <option value="0">Select Month</option>
                              {Array.isArray(monthNames) ? monthNames.map((item, index) => {
                                return (
                                  <>
                                   <option value={index + 1}>{item}</option>
                                   </>
                                )
                              }) : null}
                             
                            </select>
                            <div className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16.16"
                                height="17.486"
                                viewBox="0 0 16.16 17.486"
                              >
                                <g id="sort" transform="translate(0 0)">
                                  <path
                                    id="Path_11988"
                                    data-name="Path 11988"
                                    d="M53.177-479.89a1.386,1.386,0,0,0-.429.355c-.16.172-1.023,1.058-1.913,1.964C49.183-475.884,49-475.666,49-475.4a.8.8,0,0,0,.269.613.757.757,0,0,0,.921.164c.066-.031.648-.59,1.3-1.234l1.179-1.179v4.447c0,3.4.012,4.482.047,4.607a.879.879,0,0,0,.187.3.847.847,0,0,0,1.4-.383c.031-.148.043-1.5.043-4.665v-4.458l1.3,1.3a12.954,12.954,0,0,0,1.413,1.327.847.847,0,0,0,1.089-.808.963.963,0,0,0-.066-.336c-.035-.066-.91-.953-1.948-1.971s-1.936-1.909-2-1.983A.906.906,0,0,0,53.177-479.89Z"
                                    transform="translate(-49 479.938)"
                                  />
                                  <path
                                    id="Path_11989"
                                    data-name="Path 11989"
                                    d="M232.573-351.854a.88.88,0,0,0-.445.39,35.329,35.329,0,0,0-.078,4.661l-.008,4.548-1.261-1.253c-1.206-1.2-1.265-1.253-1.456-1.3a.834.834,0,0,0-.933,1.167c.031.059.867.9,1.862,1.878s1.839,1.827,1.874,1.893a.892.892,0,0,0,1.2.336c.265-.144,3.877-3.822,4.037-4.115a.724.724,0,0,0-.164-.933.744.744,0,0,0-.941-.148c-.074.035-.672.605-1.327,1.261l-1.191,1.195-.02-4.536-.02-4.54-.109-.187A.848.848,0,0,0,232.573-351.854Z"
                                    transform="translate(-221.306 356.907)"
                                  />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <CoursePerformance courseDetails={courseDetails} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AdminReports />
          {/* <div className="col-xl-12 col-lg-12">
            <div className="outer-box four">
              <div className="row">
                <div className="col-xl-6 col-lg-6">
                  <div className="inner-box">
                    <div className="cflex">
                      <div className="heading">
                        <h1>Course wise learner time engagement</h1>
                      </div>
                      <div className="error-req">
                        <div className="">
                          <div className="select">
                            <select
                              name=""
                              id=""
                              value={learnerTimeFilter}
                              onChange={filterLearnerTime}
                            >
                              <option value="0">Day</option>
                              <option value="1">Week</option>
                              <option value="2">Month</option>
                            </select>
                            <div className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16.16"
                                height="17.486"
                                viewBox="0 0 16.16 17.486"
                              >
                                <g id="sort" transform="translate(0 0)">
                                  <path
                                    id="Path_11988"
                                    data-name="Path 11988"
                                    d="M53.177-479.89a1.386,1.386,0,0,0-.429.355c-.16.172-1.023,1.058-1.913,1.964C49.183-475.884,49-475.666,49-475.4a.8.8,0,0,0,.269.613.757.757,0,0,0,.921.164c.066-.031.648-.59,1.3-1.234l1.179-1.179v4.447c0,3.4.012,4.482.047,4.607a.879.879,0,0,0,.187.3.847.847,0,0,0,1.4-.383c.031-.148.043-1.5.043-4.665v-4.458l1.3,1.3a12.954,12.954,0,0,0,1.413,1.327.847.847,0,0,0,1.089-.808.963.963,0,0,0-.066-.336c-.035-.066-.91-.953-1.948-1.971s-1.936-1.909-2-1.983A.906.906,0,0,0,53.177-479.89Z"
                                    transform="translate(-49 479.938)"
                                  />
                                  <path
                                    id="Path_11989"
                                    data-name="Path 11989"
                                    d="M232.573-351.854a.88.88,0,0,0-.445.39,35.329,35.329,0,0,0-.078,4.661l-.008,4.548-1.261-1.253c-1.206-1.2-1.265-1.253-1.456-1.3a.834.834,0,0,0-.933,1.167c.031.059.867.9,1.862,1.878s1.839,1.827,1.874,1.893a.892.892,0,0,0,1.2.336c.265-.144,3.877-3.822,4.037-4.115a.724.724,0,0,0-.164-.933.744.744,0,0,0-.941-.148c-.074.035-.672.605-1.327,1.261l-1.191,1.195-.02-4.536-.02-4.54-.109-.187A.848.848,0,0,0,232.573-351.854Z"
                                    transform="translate(-221.306 356.907)"
                                  />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <CourseTimeEngagement
                      learnerTimeData={learnerTimeData}
                      themeMode={themeMode}
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6">
                  <div className="inner-box">
                    <div className="cflex">
                      <div className="heading">
                        <h1>Course wise Learner Grade</h1>
                      </div>
                      <div className="error-req">
                        <div className="">
                          <div className="select">
                            <select
                              name=""
                              id=""
                              value={learnerGradesFilter}
                              onChange={filterLearnerGrades}
                            >
                              <option value="0">Day</option>
                              <option value="1">Week</option>
                              <option value="2">Month</option>
                            </select>
                            <div className="icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16.16"
                                height="17.486"
                                viewBox="0 0 16.16 17.486"
                              >
                                <g id="sort" transform="translate(0 0)">
                                  <path
                                    id="Path_11988"
                                    data-name="Path 11988"
                                    d="M53.177-479.89a1.386,1.386,0,0,0-.429.355c-.16.172-1.023,1.058-1.913,1.964C49.183-475.884,49-475.666,49-475.4a.8.8,0,0,0,.269.613.757.757,0,0,0,.921.164c.066-.031.648-.59,1.3-1.234l1.179-1.179v4.447c0,3.4.012,4.482.047,4.607a.879.879,0,0,0,.187.3.847.847,0,0,0,1.4-.383c.031-.148.043-1.5.043-4.665v-4.458l1.3,1.3a12.954,12.954,0,0,0,1.413,1.327.847.847,0,0,0,1.089-.808.963.963,0,0,0-.066-.336c-.035-.066-.91-.953-1.948-1.971s-1.936-1.909-2-1.983A.906.906,0,0,0,53.177-479.89Z"
                                    transform="translate(-49 479.938)"
                                  />
                                  <path
                                    id="Path_11989"
                                    data-name="Path 11989"
                                    d="M232.573-351.854a.88.88,0,0,0-.445.39,35.329,35.329,0,0,0-.078,4.661l-.008,4.548-1.261-1.253c-1.206-1.2-1.265-1.253-1.456-1.3a.834.834,0,0,0-.933,1.167c.031.059.867.9,1.862,1.878s1.839,1.827,1.874,1.893a.892.892,0,0,0,1.2.336c.265-.144,3.877-3.822,4.037-4.115a.724.724,0,0,0-.164-.933.744.744,0,0,0-.941-.148c-.074.035-.672.605-1.327,1.261l-1.191,1.195-.02-4.536-.02-4.54-.109-.187A.848.848,0,0,0,232.573-351.854Z"
                                    transform="translate(-221.306 356.907)"
                                  />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <CourseGradeEngagement
                      learnerGradesData={learnerGradesData}
                      themeMode={themeMode}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default SADashboard;

import React, { useState, useEffect } from "react";

import { Line } from "react-chartjs-2";

import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const CoursePerformance = ({
  courseDetails,
}) => {

  console.log(courseDetails);

  const data = {
    labels: courseDetails && courseDetails.courseNames,
    datasets: [
      {
        label: "Course Performance",
        data: courseDetails && courseDetails.courseCounts,
        backgroundColor: "#56D364",
        borderColor: "#56D364",
        fill: false,
        borderWidth: 2,
        tension: 0.5,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        // text: (ctx) => 'Student Course Enrollment'
      },
      tooltip: {
        mode: "index",
      },
      legend: {
        display: true,
        labels: {
          boxWidth: 20,
          boxHeight: 20,
          filter: function (legendItem, data) {
            return legendItem.index != 1;
          },
        },
      },
    },
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        type: "linear",
        beginAtZero: true,
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          beginAtZero: true,
          stepSize: 15, // set the step size to 1 to display integer values
        },
        suggestedMin: 0,
        suggestedMax: 10,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  return (
    <>
      <div className="bar-area">
        <Line data={data} options={options} />
      </div>
    </>
  );
};

export default CoursePerformance;

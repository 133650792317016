import React from "react";

import { useLocation } from "react-router-dom";
import LearnerCertPreview from "./LearnerCertPreview";

const ViewCertificate = () => {
  const location = useLocation();

  const {
    cerImgSrc,
    currentUser,
    courseName,
    shortname,
    cid,
    userId,
    certLoading,
    catid,
    awarded_on,
    code,
    grade,
    certtemp,
  } = location.state;

  return (
    <div className="row">
      <div className="col-xl-12 col-lg-12 mb-3">
        <div className="outer-box">
          <div className="course-overview">
            <div className="cert-img">
              <div className="cflex">
                <div className="heading">
                  <h1>Completion Certificate</h1>
                </div>
              </div>
              <div className="certificate-preview mb-3">
                <LearnerCertPreview
                  cerImgSrc={cerImgSrc}
                  currentUser={currentUser}
                  courseName={courseName}
                  shortname={shortname}
                  cid={cid}
                  userId={userId}
                  certLoading={certLoading}
                  awarded_on={awarded_on}
                  code={code}
                  grade={grade}
                  certtemp={certtemp}
                  catid={catid}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCertificate;

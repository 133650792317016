import React, { useState, useEffect } from "react";
import ActionFlex from "./ActionFlex";
import "./accounts.css";
import { Link } from "react-router-dom";
import { UilInfoCircle } from "@iconscout/react-unicons";
import { UilEye } from "@iconscout/react-unicons";
import { Modal } from "react-bootstrap";
import "../../assets/css/datatable.css";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $, { data, type } from "jquery";
import InvoiceService from "../../services/invoice.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import TextButton from "../Widgets/TextButton";

import Invoice from "./Invoice";

import html2pdf from "html2pdf.js";

import { renderToString } from "react-dom/server";
import AuthService from "../../services/auth.service";

function PendingInvoice({ roleid }) {
  const [selectedStatus, setSelectedStatus] = useState("");
  const [showComment, setShowComment] = useState(false);
  const [comment, setComment] = useState("");
  const [invoiveNo, setInvoiveNo] = useState("");
  const [viewComment, setViewComment] = useState("");

  const [addCreditNote, setAddCreditNote] = useState(false);

  const [crnoteamt, setCrnoteamt] = useState("");

  const [crnotereason, setCrnotereason] = useState("");

  const [crnoteinv, setCrnoteinv] = useState("");

  const handleCloseCreditNote = () => {
    setAddCreditNote(false);
  };

  const handleShowCreditNote = (e) => {
    setAddCreditNote(true);
    setCrnoteinv(e.currentTarget.getAttribute("data-bs-target"));
  };

  const [userid, setUserid] = useState("");

  const [paymentDate, setPaymentDate] = useState("");

  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const [invoiceLists, setInvoiceLists] = useState([]);

  const [invoiceDetails, setInvoiceDetails] = useState([]);

  const [otherInvDetails, setOtherInvDetails] = useState({
    invoiceNo: "",
    invoiceDate: "",
    totalAmt: "",
    totalAmtWords: "",
  });

  const [verifyInvDetails, setVerifyInvDetails] = useState({
    fdate: "",
    tdate: "",
    invonum: "",
  });

  const [selectedStatusList, setSelectedStatusList] = useState(
    invoiceLists.map(() => "NA")
  );

  const handlePaymentStatusChange = (status, index, invno) => {
    setSelectedStatusList((prevState) => {
      const newState = [...prevState];
      newState[index] = status;
      return newState;
    });
    setSelectedStatus(status);
    setInvoiveNo(invno);
  };

  const handleApprovalShow = (e, invDetails, inv) => {
    setShowApprovalModal(true);
    // console.log(invDetails);
    setSelectedStatus(e.target.value);
    setInvoiveNo(inv.invoice_no);
    setInvoiceDetails(invDetails);
    setOtherInvDetails({
      invoiceNo: inv.invoice_no,
      invoiceDate: inv.cdate,
      totalAmt: inv.totalamt,
      totalAmtWords: inv.amtinword,
    });
  };

  const handleApprovalClose = () => {
    setShowApprovalModal(false);
    setSelectedStatus("NA");
  };

  const handleVerificationShow = (e, invDetails) => {
    setVerifyInvDetails({
      fdate: invDetails.datefrom,
      tdate: invDetails.dateto,
      invonum: invDetails.invoice_no,
    });
    setShowVerificationModal(true);
  };

  const handleVerificationClose = () => {
    setShowVerificationModal(false);
  };

  const handleClose = () => {
    setShowComment(false);
    setSelectedStatus("NA");
  };

  const handleShow = (e, cmt) => {
    setShowComment(true);
    setViewComment(cmt);
  };

  const handleCommentClose = () => {
    setShowCommentModal(false);
    setSelectedStatus("NA");
  };

  const handlePaymentClose = () => {
    setShowPaymentModal(false);
    setSelectedStatus("NA");
  };

  const getColorForOption = (optionValue) => {
    switch (optionValue) {
      case "inProcess":
        return "#7DC9FC";
      case "comment":
        return "#E2C13B";
      case "approved":
        return "#39B91A";
      default:
        return "white";
    }
  };

  useEffect(() => {
    if (selectedStatus === "2") {
      setShowApprovalModal(true);
      setComment("");
    } else if (selectedStatus === "1") {
      setShowCommentModal(true);
    } else if (selectedStatus === "paid") {
      setShowPaymentModal(true);
    }
  }, [selectedStatus]);

  useEffect(() => {
    getInvoiceList();
  }, []);

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    setUserid(user.user);
  }, []);

  const getInvoiceList = () => {
    InvoiceService.getInvoiceList()
      .then((resp) => {
        // console.log(resp.data);
        const invList = resp.data.filter(
          (item) => item.invoice.statusflag !== "4"
        );
        setInvoiceLists(invList);
        loadDatatable();
      })
      .catch((e) => console.log(e));
  };

  const loadDatatable = () => {
    if (!$.fn.DataTable.isDataTable("#allLearners")) {
      function filterGlobal() {
        $("#allLearners")
          .DataTable()
          .search(
            $("#global_filter").val(),
            $("#global_regex").prop("checked"),
            $("#global_smart").prop("checked")
          )
          .draw();
      }
      function filterColumn(i) {
        $("#allLearners")
          .DataTable()
          .column(i)
          .search(
            $("#col" + i + "_filter").val(),
            $("#col" + i + "_regex").prop("checked"),
            $("#col" + i + "_smart").prop("checked")
          )
          .draw();
      }

      $(document).ready(function () {
        setTimeout(function () {
          $("#allLearners").DataTable({
            pagingType: "full_numbers",
            responsive: true,
            pageLength: 10,
            select: true,
            bDestroy: true,

            lengthMenu: [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            columnDefs: [
              {
                targets: 0,
                render: function (data, type, row, meta) {
                  return type === "export" ? meta.row + 1 : data;
                },
              },
            ],
          });

          $("input.global_filter").on("keyup click", function () {
            filterGlobal();
          });

          $("input.column_filter").on("keyup click", function () {
            filterColumn($(this).parents("tr").attr("data-column"));
          });
        }, 0);
      });
    }
  };

  const handleChangeComment = (e) => {
    setComment(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "paymentDate") {
      setPaymentDate(value);
    }

    if (name === "crnoteamt") {
      setCrnoteamt(value);
    }

    if (name === "crnotereason") {
      setCrnotereason(value);
    }
  };

  const handleSubmit = () => {
    // console.log("invoiceDetails", invoiceDetails);
    // console.log("otherInvDetails", otherInvDetails);

    const invoiceDoc = (
      <Invoice
        invoiceDetails={invoiceDetails}
        otherInvDetails={otherInvDetails}
      />
    );

    // console.log(invoiceDoc);

    const pdfString = renderToString(invoiceDoc);

    html2pdf()
      .from(pdfString)
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        const pdfBlob = pdf.output("blob");

        const formdata = new FormData();

        formdata.append("invonm", invoiveNo);
        formdata.append("ists", "3");
        formdata.append("cmt", comment);
        formdata.append("blobimg", pdfBlob);
        setShowApprovalModal(false);

        toast.warn("Processing...", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        InvoiceService.updateInvoiceSts(formdata)
          .then((resp) => {
            // console.log(resp.data);
            if (resp.data) {
              getInvoiceList();
              toast.success("Invoice approved successfully.", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
          })
          .catch((e) => console.log(e));
      });
  };

  const handlePaymentSubmit = () => {
    const data = {
      invo: invoiveNo,
      paymentDate: paymentDate,
    };

    InvoiceService.updatePaymentSts(data)
      .then((resp) => {
        // console.log(resp.data);
        if (resp.data) {
          toast.success("Payment Date has been added successfully.", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          getInvoiceList();
        }
      })
      .catch((e) => console.log(e));
  };

  const handleAddCreditNote = () => {
    const data = {
      raisedinvonum: crnoteinv,
      Amt: crnoteamt,
      reason: crnotereason,
    };
    console.log(data);

    InvoiceService.insertCrNote(data)
      .then((resp) => {
        console.log(resp.data);
        if (resp.data) {
          toast.success("Credei Note Added to selected Invoice successfully.", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const handleVerifyInvoice = () => {
    InvoiceService.verifyInvList(verifyInvDetails)
      .then((resp) => {
        // console.log(resp.data);
        if (resp.data) {
          setShowVerificationModal(false);
          getInvoiceList();
          toast.success("Invoice verified successfully.", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="box-outer right">
              <div className="box-inner">
                <ActionFlex />
                <ToastContainer />
                <div className="search-bar">
                  <input
                    type="text"
                    className="global_filter"
                    id="global_filter"
                    placeholder="Start typing to search..."
                  />
                </div>
                <div className="admin-users">
                  <div className="table-responsive">
                    <table className="table" id="allLearners">
                      <thead>
                        <tr>
                          <th>Invoice No.</th>
                          <th>Invoice Amount</th>
                          <th>From Date</th>
                          <th>To Date</th>
                          <th>Invoice Date</th>
                          <th>Course Enrollment List</th>
                          <th>Invoice</th>
                          <th>Credit Note</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(invoiceLists)
                          ? invoiceLists.map((invoiceList, index) => {
                              return (
                                <>
                                  <tr>
                                    <td>
                                      <span>
                                        {invoiceList.invoice.invoice_no}
                                      </span>
                                    </td>
                                    <td>
                                      <span>
                                        {invoiceList.invoice.totalamt} BHD
                                      </span>
                                    </td>
                                    <td>
                                      <span>
                                        {invoiceList.invoice.datefrom}
                                      </span>
                                    </td>
                                    <td>
                                      <span>{invoiceList.invoice.dateto}</span>
                                    </td>

                                    <td>
                                      <span>{invoiceList.invoice.cdate}</span>
                                    </td>

                                    <td>
                                      <Link
                                        to="/StudentList"
                                        className="icon"
                                        state={{
                                          isPaid: false,
                                          fdate: invoiceList.invoice.datefrom,
                                          tdate: invoiceList.invoice.dateto,
                                          invid: invoiceList.invoice.invoice_id,
                                          invno: invoiceList.invoice.invoice_no,
                                          roleid: roleid,
                                        }}
                                      >
                                        <UilEye size="24" />
                                      </Link>
                                    </td>

                                    {roleid === "6" ||
                                    userid === "5" ||
                                    roleid === "1" ||
                                    roleid === "2" ? (
                                      <>
                                        <td>
                                          <span className="exl-down">
                                            <Link
                                              to="/Invoice"
                                              state={{
                                                isPaid: false,
                                                invno:
                                                  invoiceList.invoice
                                                    .invoice_no,
                                              }}
                                            >
                                              <svg
                                                id="excel-file"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18.889"
                                                height="18.886"
                                                viewBox="0 0 18.889 18.886"
                                              >
                                                <path
                                                  id="Path_12182"
                                                  data-name="Path 12182"
                                                  d="M5.423-510.852C2.45-510.277.015-509.8.011-509.8S0-506.5,0-502.459v7.342l.081.018c.048.011,2.461.476,5.361,1.033s5.313,1.022,5.361,1.033l.081.018v-9.445c0-5.195-.011-9.445-.026-9.441S8.4-511.428,5.423-510.852Zm-.616,5.885c.3.627.579,1.236.62,1.354s.081.24.092.269.077-.1.162-.314.387-.834.694-1.4l.557-1.033.926-.011.93-.007-.074.118c-.627,1.029-2.084,3.453-2.1,3.486s1.819,3.084,2.2,3.656l.055.081-.992-.007-.989-.011-.657-1.236a13.05,13.05,0,0,1-.7-1.431c-.026-.1-.052-.185-.063-.177s-.052.125-.1.255-.369.771-.719,1.424l-.635,1.184H3.04c-.535,0-.974-.011-.974-.022s1.033-1.645,2.2-3.468l.122-.188-.978-1.675-1.07-1.834-.092-.155H4.265Z"
                                                  transform="translate(0 511.9)"
                                                  // fill="#fff"
                                                />
                                                <path
                                                  id="Path_12183"
                                                  data-name="Path 12183"
                                                  d="M315-451.912v1.088h2.214v1.476H315v1.439h2.177v1.439H315v1.476h2.177v1.439H315v1.439h2.177v1.476H315v2.177h3.376a32.513,32.513,0,0,0,3.5-.052.726.726,0,0,0,.354-.365c.03-.074.041-2.177.041-6.855,0-5.648-.007-6.77-.052-6.87a.726.726,0,0,0-.365-.354c-.07-.03-1.184-.041-3.479-.041H315Zm5.822,1.815-.011.73-1.446.011-1.45.007v-1.476h2.915Zm.007,2.907v.719h-2.915v-1.439h2.915Zm0,2.915v.719h-2.915v-1.439h2.915Zm-.007,2.9.007.73h-2.915v-1.476l1.45.007,1.446.011Z"
                                                  transform="translate(-303.379 455.173)"
                                                  // fill="#fff"
                                                />
                                              </svg>
                                            </Link>
                                          </span>
                                        </td>
                                      </>
                                    ) : invoiceList.invoice.statusflag ===
                                      "3" ? (
                                      <>
                                        <td>
                                          <span className="exl-down">
                                            <Link
                                              to="/Invoice"
                                              state={{
                                                isPaid: false,
                                                invno:
                                                  invoiceList.invoice
                                                    .invoice_no,
                                              }}
                                            >
                                              <svg
                                                id="excel-file"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18.889"
                                                height="18.886"
                                                viewBox="0 0 18.889 18.886"
                                              >
                                                <path
                                                  id="Path_12182"
                                                  data-name="Path 12182"
                                                  d="M5.423-510.852C2.45-510.277.015-509.8.011-509.8S0-506.5,0-502.459v7.342l.081.018c.048.011,2.461.476,5.361,1.033s5.313,1.022,5.361,1.033l.081.018v-9.445c0-5.195-.011-9.445-.026-9.441S8.4-511.428,5.423-510.852Zm-.616,5.885c.3.627.579,1.236.62,1.354s.081.24.092.269.077-.1.162-.314.387-.834.694-1.4l.557-1.033.926-.011.93-.007-.074.118c-.627,1.029-2.084,3.453-2.1,3.486s1.819,3.084,2.2,3.656l.055.081-.992-.007-.989-.011-.657-1.236a13.05,13.05,0,0,1-.7-1.431c-.026-.1-.052-.185-.063-.177s-.052.125-.1.255-.369.771-.719,1.424l-.635,1.184H3.04c-.535,0-.974-.011-.974-.022s1.033-1.645,2.2-3.468l.122-.188-.978-1.675-1.07-1.834-.092-.155H4.265Z"
                                                  transform="translate(0 511.9)"
                                                  // fill="#fff"
                                                />
                                                <path
                                                  id="Path_12183"
                                                  data-name="Path 12183"
                                                  d="M315-451.912v1.088h2.214v1.476H315v1.439h2.177v1.439H315v1.476h2.177v1.439H315v1.439h2.177v1.476H315v2.177h3.376a32.513,32.513,0,0,0,3.5-.052.726.726,0,0,0,.354-.365c.03-.074.041-2.177.041-6.855,0-5.648-.007-6.77-.052-6.87a.726.726,0,0,0-.365-.354c-.07-.03-1.184-.041-3.479-.041H315Zm5.822,1.815-.011.73-1.446.011-1.45.007v-1.476h2.915Zm.007,2.907v.719h-2.915v-1.439h2.915Zm0,2.915v.719h-2.915v-1.439h2.915Zm-.007,2.9.007.73h-2.915v-1.476l1.45.007,1.446.011Z"
                                                  transform="translate(-303.379 455.173)"
                                                  // fill="#fff"
                                                />
                                              </svg>
                                            </Link>
                                          </span>
                                        </td>
                                      </>
                                    ) : (
                                      <>
                                        <td>
                                          <span></span>
                                        </td>
                                      </>
                                    )}

                                    <td>
                                      <div className="">
                                        <button
                                          className="add-btn"
                                          data-bs-target={
                                            invoiceList.invoice.invoice_no
                                          }
                                          onClick={handleShowCreditNote}
                                        >
                                          +
                                        </button>
                                      </div>
                                    </td>

                                    <td>
                                      <div className="d-flex gap-1 commentsts">
                                        {invoiceList.invoice.statusflag ===
                                        "0" ? (
                                          <>
                                            <span className="inPro-op">
                                              Pending
                                            </span>
                                          </>
                                        ) : invoiceList.invoice.statusflag ===
                                          "1" ? (
                                          <>
                                            <span className="inPro-op">
                                              Verified
                                            </span>
                                          </>
                                        ) : invoiceList.invoice.statusflag ===
                                          "2" ? (
                                          <>
                                            <span className="inPro-op">
                                              On Hold
                                            </span>
                                            <p
                                              className="icon"
                                              title="View Comment"
                                            >
                                              <UilInfoCircle
                                                size="24"
                                                onClick={(e) =>
                                                  handleShow(
                                                    e,
                                                    invoiceList.invoice.cmt
                                                  )
                                                }
                                              />
                                            </p>
                                          </>
                                        ) : invoiceList.invoice.statusflag ===
                                          "3" ? (
                                          <>
                                            <span className="inPro-op">
                                              Approved
                                            </span>
                                          </>
                                        ) : invoiceList.invoice.statusflag ===
                                          "4" ? (
                                          <>
                                            <span className="inPro-op">
                                              Paid
                                            </span>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </td>
                                    <td>
                                      {roleid === "6" ? (
                                        <>
                                          {invoiceList.invoice.statusflag ===
                                          "3" ? (
                                            <>
                                              <div className="assign-req invoice">
                                                <div className="select">
                                                  <select
                                                    name=""
                                                    id={
                                                      invoiceList.invoice
                                                        .invoice_no
                                                    }
                                                    style={{
                                                      color:
                                                        getColorForOption(
                                                          selectedStatus
                                                        ),
                                                    }}
                                                    value={
                                                      selectedStatusList[index]
                                                    }
                                                    onChange={(e) =>
                                                      handlePaymentStatusChange(
                                                        e.target.value,
                                                        index,
                                                        invoiceList.invoice
                                                          .invoice_no
                                                      )
                                                    }
                                                  >
                                                    <option value="NA">
                                                      Select
                                                    </option>
                                                    <option
                                                      value="paid"
                                                      className="comm-op"
                                                    >
                                                      Paid
                                                    </option>
                                                    <option
                                                      value="unpaid"
                                                      className="app-op"
                                                    >
                                                      Unpaid
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                            </>
                                          ) : invoiceList.invoice.statusflag ===
                                            "2" ? (
                                            <>
                                              <TextButton
                                                text="Verify"
                                                onClick={(e) =>
                                                  handleVerificationShow(
                                                    e,
                                                    invoiceList.invoice
                                                  )
                                                }
                                              />
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {invoiceList.invoice.statusflag !==
                                          "3" ? (
                                            <>
                                              <TextButton
                                                text="Approve"
                                                onClick={(e) =>
                                                  handleApprovalShow(
                                                    e,
                                                    invoiceList.invoicedetail,
                                                    invoiceList.invoice
                                                  )
                                                }
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <span>Invoice Generated</span>
                                            </>
                                          )}
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showApprovalModal} onHide={handleApprovalClose} centered>
        <Modal.Header>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleApprovalClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p className="sure-body">Are you sure you want to approve?</p>
        </Modal.Body>
        <Modal.Footer className="footer-of-modal">
          <button className="no " onClick={handleApprovalClose}>
            No
          </button>
          <button
            className="yes"
            onClick={() => {
              handleSubmit();
            }}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showVerificationModal}
        onHide={handleVerificationClose}
        centered
      >
        <Modal.Header>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleVerificationClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p className="sure-body">Are you sure you want to verify changes?</p>
        </Modal.Body>
        <Modal.Footer className="footer-of-modal">
          <button className="no " onClick={handleVerificationClose}>
            No
          </button>
          <button
            className="yes"
            onClick={() => {
              handleVerifyInvoice();
            }}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCommentModal} onHide={handleCommentClose} centered>
        <Modal.Header>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleCommentClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p className="sure-body">
            Please add comment if there is any problem in this invoice?
          </p>
          <textarea
            className="comment-textarea"
            value={comment}
            onChange={handleChangeComment}
          />
        </Modal.Body>
        <Modal.Footer className="footer-of-modal">
          <button className="no " onClick={handleCommentClose}>
            No
          </button>
          <button
            className="yes"
            onClick={() => {
              handleSubmit();
            }}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showComment} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Comment</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p className="sure-body">{viewComment}</p>
        </Modal.Body>
      </Modal>

      <Modal show={showPaymentModal} onHide={handlePaymentClose} centered>
        <Modal.Header>
          <Modal.Title>Payment Confirmation</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handlePaymentClose}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="paymentDate">
            <Form.Label>Invoice Paid On *</Form.Label>
            <div className="input-box">
              <Form.Control
                type="date"
                name="paymentDate"
                id="paymentDate"
                value={paymentDate}
                onChange={handleInputChange}
              />
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="14.02"
                  viewBox="0 0 81.923 76.58"
                >
                  <g id="calendar" transform="translate(-15 482)">
                    <path
                      id="Path_13206"
                      data-name="Path 13206"
                      d="M20.071-481.763a6.872,6.872,0,0,0-4.139,3.494c-.8,1.577-.933,2.68-.933,8.175v5.054H96.77l-.051-5.394-.051-5.377-.543-1.153a9.468,9.468,0,0,0-6.31-4.8A33.656,33.656,0,0,0,84.592-482H80.47v3.85a39.256,39.256,0,0,1-.17,4.461,3,3,0,0,1-.763,1.187,1.965,1.965,0,0,1-1.476.645,1.8,1.8,0,0,1-1.476-.339c-1.289-.882-1.289-.865-1.34-5.58L75.179-482H34.505v3.681c-.017,4.427-.1,4.987-.9,5.767a2.54,2.54,0,0,1-3.9-.441c-.356-.577-.373-.814-.424-4.817L29.23-482l-4.189.017A31.215,31.215,0,0,0,20.071-481.763Z"
                    />
                    <path
                      id="Path_13207"
                      data-name="Path 13207"
                      d="M15.034-326.627l.051,24.39.441,1.119a6.553,6.553,0,0,0,4.122,4.172c.814.288,2.612.305,35.551.305H89.884l1.136-.39a9.341,9.341,0,0,0,5.275-4.444l.543-1.1.051-24.2L96.923-351H15ZM38.9-341.281a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.935.051,3.155.034,3.528-.271,4.223-.594,1.34-.746,1.374-5.054,1.374-5.343,0-5.156.2-5.156-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,38.9-341.281Zm20.184,0a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.986.068,3.9-.051,4.58-.865,5.19-.458.339-.7.356-4.359.356-5.462,0-5.258.22-5.258-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,59.082-341.281Zm20.54.034c.933.492,1.018.882,1.018,5,0,5.445.271,5.173-5.241,5.173-3.731,0-3.85-.017-4.376-.39-.78-.56-.9-1.187-.9-4.919,0-3.562.1-4.1.933-4.749.458-.356.628-.373,4.274-.373C78.4-341.5,79.232-341.451,79.622-341.247ZM39.2-318.129c.78.56.9,1.187.9,4.834,0,3.46-.1,3.935-.916,4.7-.458.424-.475.424-4.274.475-3.613.051-3.85.034-4.376-.305-.916-.56-.967-.882-.916-5.139.051-3.782.051-3.833.458-4.257a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C38.508-318.519,38.678-318.5,39.2-318.129Zm20.082-.085c.848.577.916.9.916,4.851a36.048,36.048,0,0,1-.136,4.139,1.659,1.659,0,0,1-.678.8c-.492.305-.865.339-4.325.339-5.563,0-5.326.237-5.258-5.428.051-3.85.051-3.884.458-4.308a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C58.37-318.519,58.9-318.485,59.286-318.214Zm19.845-.221a2.965,2.965,0,0,1,1.018.611l.407.441v8.277l-.475.458-.458.475H71.345l-.441-.407c-.712-.678-.814-1.34-.746-5.139.068-4.953-.017-4.868,5.139-4.868A28.455,28.455,0,0,1,79.131-318.434Z"
                      transform="translate(0 -108.781)"
                    />
                  </g>
                </svg>
              </span>
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="footer-of-modal">
          <button className="no " onClick={handlePaymentClose}>
            No
          </button>
          <button
            className="yes"
            onClick={() => {
              handlePaymentSubmit();
            }}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={addCreditNote} onHide={handleCloseCreditNote} centered>
        <Modal.Header>
          <Modal.Title>Add Credit Note</Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={handleCloseCreditNote}
          >
            <svg
              id="incorrect"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24.472 24.445"
            >
              <path
                id="Path_11894"
                data-name="Path 11894"
                d="M53.513-468.776a12.275,12.275,0,0,0-10.047,8.325,12.483,12.483,0,0,0-.54,5.184A12.274,12.274,0,0,0,53.6-444.485a15.674,15.674,0,0,0,2.842.023,12.26,12.26,0,0,0,9.645-6.8,12.267,12.267,0,0,0,.6-9.283,12.331,12.331,0,0,0-6.419-7.176,12.8,12.8,0,0,0-3.829-1.079A17.215,17.215,0,0,0,53.513-468.776Zm-2.2,6.723c.1.052,1,.9,1.986,1.877l1.791,1.791,1.8-1.791c.982-.982,1.872-1.826,1.969-1.877a1.151,1.151,0,0,1,1.407.247,1.152,1.152,0,0,1,.247,1.407c-.052.1-.9.987-1.877,1.969l-1.791,1.8,1.791,1.791c.982.987,1.831,1.889,1.889,2a1.5,1.5,0,0,1,.011,1.062,1.9,1.9,0,0,1-.62.609,1.561,1.561,0,0,1-1.033-.029c-.109-.057-1.01-.907-1.992-1.889l-1.8-1.791L53.3-453.091c-.987.982-1.877,1.826-1.975,1.877a1.152,1.152,0,0,1-1.407-.247,1.152,1.152,0,0,1-.247-1.407c.052-.1.9-.987,1.877-1.975l1.791-1.791-1.791-1.8c-.982-.982-1.826-1.872-1.877-1.969a1.254,1.254,0,0,1,.746-1.745A1.431,1.431,0,0,1,51.314-462.053Z"
                transform="translate(-42.855 468.853)"
                // fill="#fff"
              />
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="paymentDate">
            <Form.Label>Credit Note Amount *</Form.Label>
            <div className="input-box">
              <Form.Control
                type="text"
                name="crnoteamt"
                id="crnoteamt"
                value={crnoteamt}
                onChange={handleInputChange}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="paymentDate">
            <Form.Label>Reason</Form.Label>
            <div className="input-box">
              <Form.Control
                type="text"
                name="crnotereason"
                id="crnotereason"
                value={crnotereason}
                onChange={handleInputChange}
              />
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="footer-of-modal">
          <button className="no " onClick={handleCloseCreditNote}>
            No
          </button>
          <button
            className="yes"
            onClick={() => {
              handleAddCreditNote();
            }}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PendingInvoice;

import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const CoursesStatistic = ({
  yAxisData,
  corsid,
  courseEnrolmentValue,
  courseComplitionValue,
}) => {

  const [chartHeight, setChartHeight] = useState(150);

  useEffect(() => {
    const handleResize = () => {
      setChartHeight(window.innerWidth < 600 ? 200 : 400);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const data = {
    labels: yAxisData,
    datasets: [
      {
        label: "Completion",
        data: courseComplitionValue,
        backgroundColor: "#F98436",
        borderColor: "#F98436",
        fill: false,
        borderWidth: 2,
        tension: 0.4,
      },
      {
        label: "Enrollment",
        data: courseEnrolmentValue,
        backgroundColor: "#63EC88",
        borderColor: "#63EC88 ",
        fill: false,
        borderWidth: 2,
        tension: 0.4,
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
      },
      tooltip: {
        mode: "index",
      },
      legend: {
        display: true,
        labels: {
          boxWidth: 20,
          boxHeight: 20,
          filter: function (legendItem, data) {
            return legendItem.index != 1;
          },
        },
      },
    },
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        type: "linear",
        beginAtZero: true,
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          beginAtZero: true,
          stepSize: 1, // set the step size to 1 to display integer values
        },
        suggestedMin: 0,
        suggestedMax: 10,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  return (
    <>
      <Line data={data} options={options} height={chartHeight} />
    </>
  );
};

export default CoursesStatistic;

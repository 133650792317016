import React, { useEffect, useState, useRef } from "react";
import "./CourseManagement.css";
import { Form, Alert, Tab, Tabs, FloatingLabel } from "react-bootstrap";
import AuthService from "../../../services/auth.service";
import Select from "react-select";
import CoursecatService from "../../../services/coursecat.service";
import CourseService from "../../../services/course.service";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { convertToHTML, convertFromHTML } from "draft-convert";

import draftToHtml from "draftjs-to-html";

import { stateFromHTML } from "draft-js-import-html";

const CourseDetails = ({ nextStep, cid }) => {
  const [courseDetails, setCourseDetails] = useState({
    user_id: "",
    courseimg: "",
    name: "",
    shortname: "",
    catid: "",
    description: "",
    courseid: cid,
    startDate: "",
    coursePeriod: "",
    endDate: "",
  });

  let navigate = useNavigate();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    setChangeData(true);
  };

  const [code, setCode] = useState(`function add(a, b) {\n  return a + b;\n}`);

  const [uploaded, setUploaded] = useState(false);
  const [dataUpload, setDataUpload] = useState(false);
  const [message, setMessage] = useState("");
  const [courseCat, setCourseCat] = useState({});
  const [error, setError] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const [courseimgPreview, setCourseimgPreview] = useState("");

  const [selectedCourseCat, setSelectedCourseCat] = useState();

  const [userid, setUserId] = useState("");

  const [chnageData, setChangeData] = useState(false);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    setCourseDetails({ ...courseDetails, user_id: user.user });

    setUserId(user.user);

    CoursecatService.getCourseCat(user.user)
      .then((response) => {
        setCourseCat(
          response.data.map((coursecat) => ({
            value: coursecat.id,
            label: coursecat.course_catename,
          }))
        );
      })
      .catch((e) => {
        console.log(e);
      });

    if (cid) {
      CourseService.getCourseById(user.user, cid)
        .then((response) => {
          if (
            response.data &&
            response.data.course_info &&
            response.data.course_info[0]
          ) {
            const courseInfo = response.data.course_info[0];

            setCourseDetails({
              courseimg: courseInfo.course_image,
              name: courseInfo.name,
              catid: courseInfo.category_id,
              description: courseInfo.description,
              startDate: courseInfo.start_date,
              endDate: courseInfo.end_date,
              courseid: cid,
              shortname: courseInfo.shortname
            });

            if (courseInfo.description) {
              const contentState = stateFromHTML(courseInfo.description);
              const editorState = EditorState.createWithContent(contentState);
              setEditorState(editorState);
            }
          }

          if (
            response.data &&
            response.data.course_info &&
            response.data.course_info[0]
          ) {
            setSelectedCourseCat(response.data.course_info[0].category_id);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, []);

  const [validated, setValidated] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCourseDetails({ ...courseDetails, [name]: value });
    setChangeData(true);
    const storedCourseData = localStorage.getItem("coursesdata");
    if (storedCourseData) {
      const localData = JSON.parse(storedCourseData);
      const names = localData.map((res) => res.name);

      if (names.includes(courseDetails.name)) {
        setError(true);
        setErrMessage("Course name already exists!");
      } else {
        setError(false);
        setErrMessage("");
      }
    }
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    setCourseDetails({ ...courseDetails, [name]: e.target.files[0] });
    setCourseimgPreview(URL.createObjectURL(e.target.files[0]));
    setUploaded(true);
    setChangeData(true);
  };

  const handleCourseCat = (selectedCourseCat) => {
    setSelectedCourseCat(selectedCourseCat);
    const value = selectedCourseCat ? selectedCourseCat.value : "";
    setCourseDetails({ ...courseDetails, catid: value });
    setChangeData(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      // setError(true);
      // setErrMessage("*Please fill all required fields!");

      toast.warn("Please fill all required fields.!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setTimeout(() => {
        setError(false);
      }, 2000);
      return;
    }

    const formData = new FormData();

    const contentState = editorState.getCurrentContent();
    const htmlContent = convertToHTML(contentState);

    if (chnageData) {
      if (!error) {
        if (cid) {
          formData.append("coursename", courseDetails.name);
          formData.append("shortname", courseDetails.shortname);
          formData.append("cateid", courseDetails.catid);
          formData.append("courseimg", courseDetails.courseimg);
          formData.append("description", htmlContent);
          formData.append("startDate", courseDetails.startDate);
          formData.append("validity", courseDetails.coursePeriod);
          formData.append("endDate", courseDetails.endDate);
          formData.append("usp", userid);
          formData.append("courseid", cid);

          CourseService.addCourseDetails(formData)
            .then((response) => {
              toast.success("Course Details Added Successfully!", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });

              setTimeout(() => {
                // setDataUpload(false);
                navigate("/CourseDraft/", { state: cid });
                nextStep();
              }, 2000);
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          formData.append("coursename", courseDetails.name);
          formData.append("shortname", courseDetails.shortname);
          formData.append("cateid", courseDetails.catid);
          formData.append("courseimg", courseDetails.courseimg);
          formData.append("description", htmlContent);
          formData.append("startDate", courseDetails.startDate);
          formData.append("validity", courseDetails.coursePeriod);
          formData.append("endDate", courseDetails.endDate);
          formData.append("usp", userid);

          CourseService.addCourseDetails(formData)
            .then((response) => {
              let cid = response.data;
              toast.success("Course Details Added Successfully!", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });

              setTimeout(() => {
                navigate("/CourseDraft", { state: { cid } });
                nextStep();
              }, 2000);
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
    } else {
      nextStep();
    }
  };

  return (
    <>
      <ToastContainer />
      <Form noValidate validated={validated}>
        <div className="course-img-flex">
          <div className="bulk-upload-area">
            <input
              type="file"
              id=""
              name="courseimg"
              onChange={handleFileChange}
            />
            {uploaded === true ? (
              <>
                <img src={courseimgPreview} className="img-fluid" alt="" />
              </>
            ) : (
              <>
                <div className="info">
                  <span>Upload Course Thumbnail</span>
                </div>
              </>
            )}
          </div>
          <div className="bulk-upload-area">
            {uploaded === false && courseDetails.courseimg !== "" ? (
              <>
                <img
                  src={
                    "https://elearning.gaa.aero/cit/" + courseDetails.courseimg
                  }
                  className="img-fluid"
                  alt=""
                />
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="add-user-form">
          <div className="mb-3">
            <Form.Group controlId="coursename">
              <Form.Label>Course Name *</Form.Label>
              <div className="input-box">
                <Form.Control
                  type="text"
                  placeholder="Course Name"
                  name="name"
                  value={courseDetails.name}
                  onChange={handleInputChange}
                  required
                />
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 20.199 20.201"
                  >
                    <g
                      id="Group_11227"
                      data-name="Group 11227"
                      transform="translate(-2402.326 -6200.833)"
                    >
                      <path
                        id="Path_11890"
                        data-name="Path 11890"
                        d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                        transform="translate(752.656 5510.435)"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                      <path
                        id="Path_11892"
                        data-name="Path 11892"
                        d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                        transform="translate(751.221 5511.583)"
                      />
                    </g>
                  </svg>
                </span>
              </div>
              <p style={{ color: "red" }}>{errMessage}</p>
            </Form.Group>
          </div>
          <Form.Group className="mb-3" controlId="shortname">
            <Form.Label>Course Short Name</Form.Label>
            <div className="input-box">
              <Form.Control
                type="text"
                placeholder="Course Name"
                name="shortname"
                value={courseDetails.shortname}
                onChange={handleInputChange}
                required
              />
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 20.199 20.201"
                >
                  <g
                    id="Group_11227"
                    data-name="Group 11227"
                    transform="translate(-2402.326 -6200.833)"
                  >
                    <path
                      id="Path_11890"
                      data-name="Path 11890"
                      d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                      transform="translate(752.656 5510.435)"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <path
                      id="Path_11892"
                      data-name="Path 11892"
                      d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                      transform="translate(751.221 5511.583)"
                    />
                  </g>
                </svg>
              </span>
            </div>
            <p style={{ color: "red" }}>{errMessage}</p>
          </Form.Group>
          <Form.Group className="mb-3" controlId="startDate">
            <Form.Label>Start Date *</Form.Label>
            <div className="input-box">
              <Form.Control
                type="date"
                name="startDate"
                onChange={handleInputChange}
                value={courseDetails.startDate}
                className="mb-3"
                min={new Date().toJSON().slice(0, 10)}
                required
              />
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="14.02"
                  viewBox="0 0 81.923 76.58"
                >
                  <g id="calendar" transform="translate(-15 482)">
                    <path
                      id="Path_13206"
                      data-name="Path 13206"
                      d="M20.071-481.763a6.872,6.872,0,0,0-4.139,3.494c-.8,1.577-.933,2.68-.933,8.175v5.054H96.77l-.051-5.394-.051-5.377-.543-1.153a9.468,9.468,0,0,0-6.31-4.8A33.656,33.656,0,0,0,84.592-482H80.47v3.85a39.256,39.256,0,0,1-.17,4.461,3,3,0,0,1-.763,1.187,1.965,1.965,0,0,1-1.476.645,1.8,1.8,0,0,1-1.476-.339c-1.289-.882-1.289-.865-1.34-5.58L75.179-482H34.505v3.681c-.017,4.427-.1,4.987-.9,5.767a2.54,2.54,0,0,1-3.9-.441c-.356-.577-.373-.814-.424-4.817L29.23-482l-4.189.017A31.215,31.215,0,0,0,20.071-481.763Z"
                    />
                    <path
                      id="Path_13207"
                      data-name="Path 13207"
                      d="M15.034-326.627l.051,24.39.441,1.119a6.553,6.553,0,0,0,4.122,4.172c.814.288,2.612.305,35.551.305H89.884l1.136-.39a9.341,9.341,0,0,0,5.275-4.444l.543-1.1.051-24.2L96.923-351H15ZM38.9-341.281a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.935.051,3.155.034,3.528-.271,4.223-.594,1.34-.746,1.374-5.054,1.374-5.343,0-5.156.2-5.156-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,38.9-341.281Zm20.184,0a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.986.068,3.9-.051,4.58-.865,5.19-.458.339-.7.356-4.359.356-5.462,0-5.258.22-5.258-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,59.082-341.281Zm20.54.034c.933.492,1.018.882,1.018,5,0,5.445.271,5.173-5.241,5.173-3.731,0-3.85-.017-4.376-.39-.78-.56-.9-1.187-.9-4.919,0-3.562.1-4.1.933-4.749.458-.356.628-.373,4.274-.373C78.4-341.5,79.232-341.451,79.622-341.247ZM39.2-318.129c.78.56.9,1.187.9,4.834,0,3.46-.1,3.935-.916,4.7-.458.424-.475.424-4.274.475-3.613.051-3.85.034-4.376-.305-.916-.56-.967-.882-.916-5.139.051-3.782.051-3.833.458-4.257a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C38.508-318.519,38.678-318.5,39.2-318.129Zm20.082-.085c.848.577.916.9.916,4.851a36.048,36.048,0,0,1-.136,4.139,1.659,1.659,0,0,1-.678.8c-.492.305-.865.339-4.325.339-5.563,0-5.326.237-5.258-5.428.051-3.85.051-3.884.458-4.308a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C58.37-318.519,58.9-318.485,59.286-318.214Zm19.845-.221a2.965,2.965,0,0,1,1.018.611l.407.441v8.277l-.475.458-.458.475H71.345l-.441-.407c-.712-.678-.814-1.34-.746-5.139.068-4.953-.017-4.868,5.139-4.868A28.455,28.455,0,0,1,79.131-318.434Z"
                      transform="translate(0 -108.781)"
                    />
                  </g>
                </svg>
              </span>
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="coursePeriod">
            <Form.Label>Course Period (in Days)</Form.Label>
            <div className="input-box">
              <Form.Control
                type="number"
                placeholder="Course Name"
                name="coursePeriod"
                value={courseDetails.coursePeriod}
                onChange={handleInputChange}
                required
              />
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 20.199 20.201"
                >
                  <g
                    id="Group_11227"
                    data-name="Group 11227"
                    transform="translate(-2402.326 -6200.833)"
                  >
                    <path
                      id="Path_11890"
                      data-name="Path 11890"
                      d="M1654.058,694.916h-1.132a2.254,2.254,0,0,0-1.595.662,2.23,2.23,0,0,0-.662,1.6V707.34a2.231,2.231,0,0,0,.662,1.6,2.254,2.254,0,0,0,1.595.662h10.165a2.259,2.259,0,0,0,2.256-2.26v-1.129"
                      transform="translate(752.656 5510.435)"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <path
                      id="Path_11892"
                      data-name="Path 11892"
                      d="M1665.659,691.509l3.389,3.389m1.563-1.6a2.373,2.373,0,0,0-3.356-3.355l-9.5,9.471V702.8h3.389Z"
                      transform="translate(751.221 5511.583)"
                    />
                  </g>
                </svg>
              </span>
            </div>
            <p style={{ color: "red" }}>{errMessage}</p>
          </Form.Group>

          {/* <Form.Group className="mb-3">
            <Form.Label>End Date</Form.Label>
            <div className="input-box">
              <Form.Control
                type="date"
                name="endDate"
                onChange={handleInputChange}
                value={courseDetails.endDate}
                min={new Date().toJSON().slice(0, 10)}
              />
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="14.02"
                  viewBox="0 0 81.923 76.58"
                >
                  <g id="calendar" transform="translate(-15 482)">
                    <path
                      id="Path_13206"
                      data-name="Path 13206"
                      d="M20.071-481.763a6.872,6.872,0,0,0-4.139,3.494c-.8,1.577-.933,2.68-.933,8.175v5.054H96.77l-.051-5.394-.051-5.377-.543-1.153a9.468,9.468,0,0,0-6.31-4.8A33.656,33.656,0,0,0,84.592-482H80.47v3.85a39.256,39.256,0,0,1-.17,4.461,3,3,0,0,1-.763,1.187,1.965,1.965,0,0,1-1.476.645,1.8,1.8,0,0,1-1.476-.339c-1.289-.882-1.289-.865-1.34-5.58L75.179-482H34.505v3.681c-.017,4.427-.1,4.987-.9,5.767a2.54,2.54,0,0,1-3.9-.441c-.356-.577-.373-.814-.424-4.817L29.23-482l-4.189.017A31.215,31.215,0,0,0,20.071-481.763Z"
                    />
                    <path
                      id="Path_13207"
                      data-name="Path 13207"
                      d="M15.034-326.627l.051,24.39.441,1.119a6.553,6.553,0,0,0,4.122,4.172c.814.288,2.612.305,35.551.305H89.884l1.136-.39a9.341,9.341,0,0,0,5.275-4.444l.543-1.1.051-24.2L96.923-351H15ZM38.9-341.281a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.935.051,3.155.034,3.528-.271,4.223-.594,1.34-.746,1.374-5.054,1.374-5.343,0-5.156.2-5.156-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,38.9-341.281Zm20.184,0a2.562,2.562,0,0,1,.763.678c.322.441.356.746.407,3.986.068,3.9-.051,4.58-.865,5.19-.458.339-.7.356-4.359.356-5.462,0-5.258.22-5.258-5.224,0-5.411-.22-5.19,5.173-5.19A16.623,16.623,0,0,1,59.082-341.281Zm20.54.034c.933.492,1.018.882,1.018,5,0,5.445.271,5.173-5.241,5.173-3.731,0-3.85-.017-4.376-.39-.78-.56-.9-1.187-.9-4.919,0-3.562.1-4.1.933-4.749.458-.356.628-.373,4.274-.373C78.4-341.5,79.232-341.451,79.622-341.247ZM39.2-318.129c.78.56.9,1.187.9,4.834,0,3.46-.1,3.935-.916,4.7-.458.424-.475.424-4.274.475-3.613.051-3.85.034-4.376-.305-.916-.56-.967-.882-.916-5.139.051-3.782.051-3.833.458-4.257a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C38.508-318.519,38.678-318.5,39.2-318.129Zm20.082-.085c.848.577.916.9.916,4.851a36.048,36.048,0,0,1-.136,4.139,1.659,1.659,0,0,1-.678.8c-.492.305-.865.339-4.325.339-5.563,0-5.326.237-5.258-5.428.051-3.85.051-3.884.458-4.308a2.607,2.607,0,0,1,.933-.611,33.768,33.768,0,0,1,4.088-.119C58.37-318.519,58.9-318.485,59.286-318.214Zm19.845-.221a2.965,2.965,0,0,1,1.018.611l.407.441v8.277l-.475.458-.458.475H71.345l-.441-.407c-.712-.678-.814-1.34-.746-5.139.068-4.953-.017-4.868,5.139-4.868A28.455,28.455,0,0,1,79.131-318.434Z"
                      transform="translate(0 -108.781)"
                    />
                  </g>
                </svg>
              </span>
            </div>
          </Form.Group> */}
          <Form.Group className="mb-3" controlId="category">
            <Form.Label>Category *</Form.Label>
            <div className="input-box">
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    border: "none",
                    outline: "none",
                    borderRadius: "10px",
                    backgroundColor: state.isFocused
                      ? "var(--dark-bg)"
                      : "var(--dark-bg)",
                    height: "45px",
                  }),
                  option: (baseStyles, { isFocused, isSelected }) => ({
                    ...baseStyles,
                    backgroundColor: isSelected
                      ? "var(--primary-color)"
                      : isFocused
                      ? "var(--secondary-color)"
                      : "var(--dark-bg)",
                    color: isSelected
                      ? "var(--dark-bg)"
                      : isFocused
                      ? "var(--dark-bg)"
                      : "var(--whiteColor)",
                  }),
                }}
                // className="react-select-container"
                // classNamePrefix="react-select"
                onChange={handleCourseCat}
                // value={selectedCourseCat}
                options={courseCat}
                issearchable="true"
                placeholder="Select Course Category"
                value={
                  Array.isArray(courseCat)
                    ? courseCat.filter(
                        (option) => courseDetails.catid === option.value
                      )
                    : []
                }
                required
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="description">
            <Form.Label>Course Description</Form.Label>
            <div className="input-box">
              <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={onEditorStateChange}
              />
              <textarea
                hidden
                value={draftToHtml(
                  convertToRaw(editorState.getCurrentContent())
                )}
                onChange={handleInputChange}
              />
            </div>
          </Form.Group>
        </div>
        <div className="flex-end">
          <input
            type="button"
            className="onboard-btn-f"
            value="Next"
            onClick={handleSubmit}
          />
        </div>
      </Form>
    </>
  );
};

export default CourseDetails;
